export interface LoginDTO {
  des_email_usu: string;
  des_senha_usu: string;
}

export interface DecodedTokenLoginDTO {
  cod_usuario_usu: number;
  des_nome_usu: string;
  role: string[];
  exp: number;
  iat: number;
}

export interface UsuarioDTO {
  cod_usuario_usu: number;
  des_usuario_usu: string;
  des_senha_usu: string;
  des_nome_usu: string;
  des_email_usu: string;
  bit_ativo_usu: boolean;
}

export interface ListarUsuarioDTO {
  cod_usuario_usu: number;
  des_nome_usu: string;
  des_email_usu: string;
  roles: number[];
}

export enum PerfilEnum {
  Admin = "1",
  Suporte = "2",
  GestorSuporte = "3",
  Desenvolvedor = "4",
  'Gestor Programacao' = "5",
  Consultor = "6",
  GestorFinanceiro = "7",
  QA = "8",
  Usuario = "99",
}
