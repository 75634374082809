import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import {
  DecodedTokenLoginDTO,
  ListarUsuarioDTO,
  LoginDTO,
  UsuarioDTO,
} from "../model/LoginDTO.model";
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseURL = `${environment.apiUrl}/usuario`;

  constructor(private http: HttpClient, private router: Router) {}

  signIn(usuario: LoginDTO): Observable<{ access_token: string }> {
    return this.http.post<{ access_token: string }>(
      `${this.baseURL}/signin`,
      usuario
    );
  }

  signOut(): void {
    window.localStorage.clear();
    this.router.navigate(["/authentication/login"]);
  }

  public saveAuthToken(token: string): void {
    window.localStorage.clear();
    window.localStorage.setItem("auth-token", token);
  }

  public getTokenObjLogin(): DecodedTokenLoginDTO | null {
    let data = localStorage.getItem("auth-token") || "";
    return jwtDecode(data);
  }

  listarTodos(): Observable<ListarUsuarioDTO[]> {
    return this.http.get<ListarUsuarioDTO[]>(this.baseURL);
  }
}
