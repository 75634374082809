import { Injectable } from "@angular/core";
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Router } from "@angular/router";
import { AuthService } from "src/app/pages/authentication/services/auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem("auth-token")) {
      if (this.tokenExpirado()) {
        this.router.navigate(["/authentication/login"], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      }

      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(["/authentication/login"], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }

  private tokenExpirado(): boolean {
    const token = this.authService.getTokenObjLogin();
    if (!token) return true;
    const currentTime = new Date().getTime() / 1000;
    return token.exp < currentTime;
  }
}
