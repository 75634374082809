import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "sample-page",
    loadChildren: () =>
      import("../../components/sample-page/sample-page.module").then(
        (m) => m.SamplePageModule
      ),
  },
  {
    path: "atendimentos",
    loadChildren: () =>
      import("../../components/atendimento/atendimento.module").then(
        (m) => m.AtendimentoModule
      ),
  },
  {
    path: "gastos",
    loadChildren: () =>
      import("../../components/gasto/gasto.module").then(
        (m) => m.GastoModule
      ),
  },
  {
    path: "relatorio",
    loadChildren: () =>
      import("../../components/relatorio/relatorio.module").then(
        (m) => m.RelatorioModule
      ),
  },
  {
    path: "numerosuporte",
    loadChildren: () =>
      import("../../components/suporte/suporte.module").then(
        (m) => m.SuporteModule
      ),
  },
  {
    path: "motivo",
    loadChildren: () =>
      import("../../components/motivo/motivo.module").then(
        (m) => m.MotivoModule
      ),
  },
  {
    path: "sistema",
    loadChildren: () =>
      import("../../components/sistema/sistema.module").then(
        (m) => m.SistemaModule
      ),
  },
  {
    path: "cliente",
    loadChildren: () =>
      import("../../components/cliente/cliente.module").then(
        (m) => m.ClienteModule
      ),
  },
  {
    path: "solicitante",
    loadChildren: () =>
      import("../../components/solicitante/solicitante.module").then(
        (m) => m.SolicitanteModule
      ),
  },
  {
    path: "rotina",
    loadChildren: () =>
      import("../../components/rotina/rotina.module").then(
        (m) => m.RotinaModule
      ),
  },
  {
    path: "usuario",
    loadChildren: () =>
      import("../../components/usuario/usuario.module").then(
        (m) => m.UsuarioModule
      ),
  },
];
